import React from "react"
import "./promotions.scss"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import Container from "react-bootstrap/Container"
import Image from "gatsby-image"
import Card from "react-bootstrap/Card"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Fade from 'react-reveal/Fade'

const PromotionsPage = props => {

  const promotionsContent = props.data.wpgraphql
  return (
    <Layout>
      <SEO
        title="Акции"
        keywords={["Акции компании Империя студия мебели", "Акции и скидки компании Империя студия мебели", "Акции студии мебели Империя", "Акции и скидки студии мебели Империя", "ООО Империя студия мебели акции", "ООО Империя студия мебели акции и скидки", "Акции Империя студия мебели СПб", "Акции и скидки Империя студия мебели СПб", "Акции компании Империя студия мебели", "Акции и скидки компании Империя студия мебели", "Акции Империя СПб", "Акции и скидки Империя СПб", "Империя акции", "Империя акции и скидки", "Империя акции скидки", "Империя СПб акции", "Империя СПб акции и скидки", "Империя акции СПб", "Империя акции и скидки СПб"]}
        description="Акции и скидки на заказы в компании Империя студия мебели в СПб - актуальная информация по акциям и скидкам"
      />
      <h1 className="text-center">Акции и скидки</h1>

      <Container fluid id="promotions-list">
        <Row className="mt-5">
          {promotionsContent.promotions.edges.map(edge => (
            <Col xs={12} sm={12} md={6} lg={6} xl={4}>
              <Fade duration={1000} delay={150}>
                <Card key={edge.node.id} className="bg-light text-white rounded-0 border-0 m-3">
                  <Image fluid={edge.node.promotionsFields.photo.imageFile.childImageSharp.fluid} />
                  <Card.ImgOverlay className="rgba-black-strong-imperia">
                    <Card.Text className="h5 d-block">{edge.node.promotionsFields.title}</Card.Text>


                  </Card.ImgOverlay>

                </Card>
              </Fade>
            </Col>

          ))}
        </Row>
      </Container>
    </Layout>
  )
}
export default PromotionsPage

export const pageQuery = graphql`
  query PromotionsQuery {
    wpgraphql {
      promotions {
        edges {
          node {
            promotionsFields {
              title
              photo {
                sourceUrl
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 700, quality: 100) {
                  ...GatsbyImageSharpFluid
                  }
                }
              }
              }
            }
            id
          }
        }
      }
    }
  }
`
